<template>
  <div class="mainContent">
    <poster-list class="poster" />
    <div class="Info">
      <el-card>
        <div class="topTip">
          <span class="yuanjiao"></span>
          <span style="color: #363e4d; font-weight: 600" class="basicMessage"
            >赞助商信息</span
          >
        </div>
        <el-form :model="form" :rules="formRules" ref="ruleForm">
          <el-form-item
            label="赞助商名称:"
            label-width="120px"
            prop="sponsorName"
          >
            <el-input
              v-model="form.sponsorName"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item label="赞助商类型:" label-width="120px">
            <dic-checkbox-group
              :code.sync="form.sponsorType"
              type-code="029"
              ref="editin"
            ></dic-checkbox-group>
          </el-form-item>
          <el-form-item label="所在地区:" label-width="120px">
            <cascader-area
              :country="form.sponsorCountry"
              :province="form.sponsorProvince"
              :city="form.sponsorCity"
              @searchCountry="searchCountry"
              @searchProvince="searchProvince"
              @searchCity="searchCity"
              ref="child"
            />
          </el-form-item>
          <el-form-item label="赞助商简介:" label-width="120px">
            <el-input type="textarea" v-model="form.sponsorIntro" :autosize="{ minRows: 8, maxRows: 20 }" style="width:80%"></el-input>
          </el-form-item>
        </el-form>
        <div class="bottomButton">
      <el-button type="primary" @click="saveItem('ruleForm')">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import posterList from "@/components/poster.vue";
import CascaderArea from "@/components/CascaderArea.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
export default {
  components: {
    posterList,
    CascaderArea,
    DicCheckboxGroup
  },
  data(){
    return{
      form: {
        sponsorName: "", //赞助商名称
        sponsorType: [], //赞助商类型
        sponsorIntro: "", //赞助商简介
        sponsorCountry: "", //所在国家
        sponsorProvince: "", //所在省份
        sponsorCity: "", //所在城市
      },
      formRules: {
        sponsorName: [
          { required: true, message: "请输入赞助商名称", trigger: "blur" },
        ],
      },
    }
  },
  methods:{
    // 国家省份城市
    searchCountry(val) {
      this.form.sponsorCountry = val;
    },
    searchProvince(val) {
      this.form.sponsorProvince = val;
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.form.sponsorCity = val;
      }
    },
    // 返回
    cancelItem() {
      this.$router.go(-1);
    },
    // 添加赞助商
    saveItem(formName) {
      this.$refs[formName].validate(async (valid) => {
        });
    },
  },
  mounted() {
      document.getElementsByClassName('Info')[0].style.height =
      document.body.clientHeight - 132 + 'px'
  },
};
</script>

<style lang="less" scoped>
.mainContent {
  display: flex;
  .poster{
    flex: 1;
    margin-right: 10px;
  }
  .Info{
    width: 80%;
    background-color: #fff;
    position: relative;
    /deep/ .el-card{
      height: 100%;
    }
  }
}
.bottomButton {
  // border-top: 1px solid #dbdbdb;
  // background: #fff;
  // padding: 10px 20px;
  // display: flex;
  // justify-content: center;
  position: absolute;
  bottom: 2%;
  left:50%;
  transform:translate(-50%);

  .el-button {
    margin: 0 20px;
  }
}
/deep/ .el-card__body {
  padding: 0px 20px 0px 20px;
}
</style>